import Vue from 'vue'
import Vuex from 'vuex'
import staticData from '@api/staticData'

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        isLogged: false,
        user: null,

        listaEstados: [],
        optionsHistoricoCliente: {
            search: "",
        },
        optionsCliente: {
            itemsPerPage: 25,
            search: "",
        },
        optionsOrcamento: {},
        optionsPedido: {},
        optionsRelatorioPedido: {},
        optionsDepositoPedido: {},
        optionsAgendaEntrega: {},
        optionsAssistencia: {},
        optionsRelatorioProduto: {},
        optionsRelatorioAniversario: {
            itemsPerPage: -1,
            aniversarioInicio: new Date().toISOString().substr(0, 10),
        },
        optionsRelatorioEstoque: {
            itemsPerPage: 25,
            withEstoque: true,
            columns: [
                'produto.id',
                'produto.nome',
                'produto.referencia',
                'entidade.nome as fornecedor_nome',
                'categoria.nome as categoria_nome',
                'quantidade',
            ],
        },
        optionsMovimentacaoEstoque: {},
        optionsRelatorioAutorizacao: {
            itemsPerPage: 25,
        },
        formXml: null,
        listaProdutoXml: [],
    },
    getters: {

    },
    mutations : {
        resetState: (state) => {
            Object.assign(state, {
                isLogged: false,
                user: null,

                listaEstados: [],
                optionsHistoricoCliente: {
                    search: "",
                },
                optionsCliente: {
                    itemsPerPage: 25,
                    search: "",
                },
                optionsOrcamento: {},
                optionsPedido: {},
                optionsRelatorioPedido: {},
                optionsDepositoPedido: {},
                optionsAgendaEntrega: {},
                optionsAssistencia: {},
                optionsRelatorioProduto: {},
                optionsRelatorioAniversario: {
                    itemsPerPage: -1,
                    aniversarioInicio: new Date().toISOString().substr(0, 10),
                },
                optionsRelatorioEstoque: {
                    itemsPerPage: 25,
                    withEstoque: true,
                    columns: [
                        'produto.id',
                        'produto.nome',
                        'produto.referencia',
                        'entidade.nome as fornecedor_nome',
                        'categoria.nome as categoria_nome',
                        'quantidade',
                    ],
                },
                optionsMovimentacaoEstoque: {},
                optionsRelatorioAutorizacao: {
                    itemsPerPage: 25,
                },
                formXml: null,
                listaProdutoXml: [],
            });
        },
        isLogged(state, payload){
            state.isLogged = payload
        },
        setUser(state, payload){
            state.user = payload
        },
        setListaEstados(state, payload){
            state.listaEstados = payload
        },
        setOptionsHistoricoCliente(state, payload){
            state.optionsHistoricoCliente.search = payload
        },
        setOptionsCliente(state, payload){
            state.optionsCliente = payload
        },
        setOptionsOrcamento(state, payload){
            state.optionsOrcamento = payload
        },
        setOptionsPedido(state, payload){
            state.optionsPedido = payload
        },
        setOptionsRelatorioPedido(state, payload){
            state.optionsRelatorioPedido = payload
        },
        setOptionsRelatorioAutorizacao(state, payload){
            state.optionsRelatorioAutorizacao = payload
        },
        setOptionsDepositoPedido(state, payload){
            state.optionsDepositoPedido = payload
        },
        setOptionsAgendaEntrega(state, payload){
            state.optionsAgendaEntrega = payload
        },
        setOptionsAssistencia(state, payload){
            state.optionsAssistencia = payload
        },
        setOptionsRelatorioProduto(state, payload){
            state.optionsRelatorioProduto = payload
        },
        setOptionsRelatorioAniversario(state, payload){
            state.optionsRelatorioAniversario = payload
        },
        setOptionsRelatorioEstoque(state, payload){
            state.optionsRelatorioEstoque = payload
        },
        setOptionsRelatorioMovimentacaoEstoque(state, payload){
            state.optionsMovimentacaoEstoque = payload
        },
        setFormXml(state, payload){
            state.formXml = payload
        },
        setListaProdutoXml(state, payload){
            state.listaProdutoXml = payload
        },
    },
    actions: {
        async loadStaticData({commit}){

            const listaStaticData = await staticData.getAll()
            commit('setListaEstados', listaStaticData.data.estados)

        }
    },
})

export default store
